@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.root {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.root[data-reach-dialog-overlay] {
	z-index: 9997;
	overflow: hidden;
	background: rgba(41, 44, 51, 0.6);
}

.dialog {
	font-family: 'Futura PT';
	height: auto;
	max-height: 84%;
	padding: 0;
	outline: 0;
	overflow: hidden;
	min-width: 1024px;
	max-width: 1048px;
	background: #ffffff;
	border-radius: 8px;
	margin: 0 32px;
	height: 720px;

	@include maxWidth($tablet + 1) {
		min-width: 95%;
	}

	@include maxWidth($mTablet + 1) {
		min-width: 93%;
		height: 702px;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background: $gray-1;
	border-radius: 4px 4px 0px 0px;
	padding: 24px 88px 24px 48px;
	position: relative;

	@include h3;
	color: $black-1;
	letter-spacing: 0.16px;

	@include maxWidth($tablet + 1) {
		padding: 24px 70px 24px 42px;
	}

	@include maxWidth($mTablet + 1) {
		padding: 24px 0px 24px 24px;
	}

	@include maxWidth($mobile + 1) {
		padding: 12px 0px 12px 20px;
	}
}

.block {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	height: calc(100% - 258px);
	margin-right: 24px;
	color: $black-1;
	margin-top: 24px;
	margin: 24px 24px 0 48px;
	font-size: 16px;
	line-height: 130%;
	font-family: $fontFuturaBook;
	padding-right: 24px;

	@include maxWidth($mTablet + 1) {
		margin: 24px 16px 0 24px;
		font-size: 14px;
		padding-right: 8px;
		height: calc(100% - 220px);
	}

	@include maxWidth($mobile + 1) {
		margin: 24px 6px 0 20px;
		padding-right: 14px;
		height: calc(100% - 256px);
	}
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 132px;
	margin: 8px 48px 0 48px;
	border-top: 1px solid $gray-1;

	@include maxWidth($mTablet + 1) {
		margin: 8px 24px 0 24px;
		height: 108px;
	}

	@include maxWidth($mobile + 1) {
		flex-direction: column;
		margin: 0;
		padding: 24px 20px 0 20px;
		border-top: none;
		box-shadow: 0px -4px 16px rgba(23, 23, 23, 0.15);
	}
}

.block::-webkit-scrollbar {
	background-color: #ffffff;
	width: 2px;
	border-radius: 2px;
}

.block::-webkit-scrollbar-thumb {
	background: $gray-1;
	border-radius: 8px;
}

.block::-webkit-scrollbar-track {
	margin: 24px 0;
}

@media (max-width: 1024px) {
	.dialog {
		width: unset;
	}
}

@media (max-width: 767px) {
	.dialog {
		width: 100%;
		height: 100%;
		max-height: unset;
		margin-top: 50px;

		&.hasBanner {
			margin-top: 105px;
		}
	}
}

@media (max-width: 660px) {
	.header {
		display: flex;
		flex-direction: column;
	}
}
